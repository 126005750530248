import React, { useState } from "react"
import Seo from "../components/SEO"
import styled from "styled-components"
import Button from "../components/Button"
import Banner from "../components/Banner"
import { StaticImage } from "gatsby-plugin-image"

import { Swiper, SwiperSlide } from "swiper/react"
// Import Swiper styles
import "swiper/swiper.min.css"
import "swiper/components/pagination/pagination.min.css"
import "swiper/components/navigation/navigation.min.css"

// import Swiper core and required modules
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper/core"

// install Swiper modules
SwiperCore.use([Autoplay, Pagination, Navigation])

const Form = styled.div`
  margin: calc(var(--spacing) * 2) 0;

  @media (min-width: 1200px) {
    margin: calc(var(--spacing) * 4) 0;
  }
`

const FormLabel = styled.label`
  font-family: var(--serif);
  font-size: var(--h5);
  margin-bottom: var(--spacing);
  display: inline-block;
`

const FormInput = styled.input`
  height: 50px;
  width: 100%;
  margin-bottom: calc(var(--spacing) * 1.5);
  font-size: var(--para);
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.25);
`

const FormTextArea = styled.textarea`
  width: 100%;
  min-height: 150px;
  margin-bottom: calc(var(--spacing) * 1.5);
  font-size: var(--para);
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.25);
`

const PetPage = () => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null)
  return (
    <>
      <Seo
        title="Claim Your Free Gift!"
        description="Get Your Free Pet Planner"
      />
      <Banner content="Claim Your Free Gift!" />
      <StaticImage
        src="../../src/content/images/planner/puppy.jpeg"
        alt="Puppy"
        width="300"
        placeholder="tracedSVG"
        layout="fullWidth"
      />
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: true,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        className="mySwiper"
      >
        <SwiperSlide>
          <StaticImage
            src="../../src/content/images/planner/1.png"
            alt="Puppy"
            width="300"
            placeholder="tracedSVG"
            layout="fullWidth"
          />
        </SwiperSlide>
        <SwiperSlide>
          <StaticImage
            src="../../src/content/images/planner/2.png"
            alt="Puppy"
            width="300"
            placeholder="tracedSVG"
            layout="fullWidth"
          />
        </SwiperSlide>
        <SwiperSlide>
          <StaticImage
            src="../../src/content/images/planner/3.png"
            alt="Puppy"
            width="300"
            placeholder="tracedSVG"
            layout="fullWidth"
          />
        </SwiperSlide>
        <SwiperSlide>
          <StaticImage
            src="../../src/content/images/planner/4.png"
            alt="Puppy"
            width="300"
            placeholder="tracedSVG"
            layout="fullWidth"
          />
        </SwiperSlide>
        <SwiperSlide>
          <StaticImage
            src="../../src/content/images/planner/5.png"
            alt="Puppy"
            width="300"
            placeholder="tracedSVG"
            layout="fullWidth"
          />
        </SwiperSlide>
      </Swiper>
      <Form>
        <form
          method="post"
          netlify-honeypot="bot-field"
          data-netlify="true"
          name="PetPlanner"
        >
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="PetPlanner" />
          <p>
            <FormLabel for="name">Amazon Order ID</FormLabel>
            <FormInput type="text" name="orderId" required />
            <span className="note">
              Please enter the Amazon Order number purchased from Amazon.com
            </span>
          </p>
          <p>
            <FormLabel for="name">Name</FormLabel>
            <FormInput type="text" name="name" required />
          </p>
          <p>
            <FormLabel for="email">Email Address</FormLabel>
            <FormInput type="email" name="email" required />
          </p>
          <p>
            <FormLabel for="message">Mailing Address</FormLabel>
            <FormTextArea type="text" name="address" required />
          </p>
          <p>
            <Button className="btn-link" text="Submit" type="submit" />
          </p>
        </form>
        <p className="center">
          You should receive your free gift in 2 weeks. <br />
          Please note that we do not ship the free planner outside of the United
          States.{" "}
        </p>
      </Form>
    </>
  )
}

export default PetPage
